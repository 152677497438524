import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route,} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';


import  panelTitle from "./public.json";
//Vars



import UserSite from './routes/UserSite';

import Register from ".//routes//login/Register";
import Login from ".//routes/login/Login";

//Dashboard
import Dashboard from ".//routes/dashboard/Dashboard";
import Settings from ".//routes/dashboard/Settings";

//Servers


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<UserSite />} />

      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />

      <Route path="dashboard" element={<Dashboard />} />
      <Route path="dashboard/settings" element={<Settings />} />
    </Routes>
  </BrowserRouter>
);


const meta = ReactDOM.createRoot(document.head);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
